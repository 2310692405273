<template>
  <v-card flat>
    <v-dialog v-model="showCompany" max-width="600px">
      <v-card v-if="company">
        <v-card-title>{{ company.name }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                label="Name"
                v-model="company.name"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Corporate Code"
                v-model="company.code"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Line 1"
                v-model="company.address.line1"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="City"
                v-model="company.address.city"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Post Code"
                v-model="company.address.postal_code"
              />
            </v-col>
          </v-row>
          <v-textarea
            label="Delivery instruction"
            v-model="company.address.delivery_instruction"/>

        </v-card-text>
        <v-card-actions>
          <v-btn @click="showCompany=false">Close</v-btn>
          <template v-if="!readOnly">
            <v-spacer/>
            <v-btn color="error" @click="confirmDelete(companyKey,company)">Delete</v-btn>
            <v-spacer/>
            <v-btn color="success" @click="save(companyKey,company)">Save</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      Corporate
      <v-spacer/>
      <v-btn outlined @click="addNewCompany">Add New</v-btn>
    </v-card-title>
    <v-card-text v-for="(c,key) of companies" v-bind:key="key">
      {{ c.name }} <b>{{ c.code }}</b>
      <br/>
      {{ formatAddress(c.address) }}
      <br/>
      {{ c.address.delivery_instruction || c.delivery_instruction }}
      <br/>
      <v-btn outlined small @click="showEdit(key, c)">Edit</v-btn>
    </v-card-text>
  </v-card>
</template>


<script>
import api from "@/api";
import {deepCopy, formatAddress} from "@/store/utils";

export default {
  name: "CorporateCodes",
  props: {
    readOnly: {type: Boolean, default: false, required: false}
  },
  data() {
    return {
      companies: [],
      company: null,
      showCompany: null,
      companyKey: null
    }
  },
  async mounted() {
    return this.fetchData();
  },
  methods: {
    async fetchData() {
      const result = await api.get('companies').then(({data}) => data);
      this.$set(this, 'companies', Object.fromEntries(
        Object.entries(result)
          .filter(e => !!e[1].address)
          .sort((a, b) => (a[1].name || '').localeCompare(b[1].name || ''))
      ));
    },
    formatAddress,
    async save(companyKey, company) {
      const result = await api.put(`companies/${companyKey}`, company)
        .then(({data}) => data);
      console.log('saved', result);
      this.showCompany = false;
      return this.fetchData();
    },
    async confirmDelete(companyKey, company) {
      if (confirm('are you sure you want to delete ' + company.name)) {
        await api.delete(`companies/${companyKey}`);
        this.showCompany = false;
        return this.fetchData();
      }
    },
    showEdit(key, c) {
      this.companyKey = deepCopy(key);
      this.company = deepCopy(c);
      this.company.address.delivery_instruction = this.company.address.delivery_instruction || this.company.delivery_instruction;
      this.showCompany = true
    },
    addNewCompany() {
      this.companyKey = window.crypto.randomUUID();
      this.company = {
        name: '',
        code: '',
        address: {
          line1: '',
          city: '',
          postal_code: '',
          delivery_instruction: ''
        }
      }
      this.showCompany = true;
    }
  },
  computed: {}
}
</script>
<style scoped>

</style>